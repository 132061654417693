import { ChevronDown } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';
import { Avatar, AvatarFallback } from './ui/avatar';
import BVUtils from '@bv/utils';
import BVUtilsOld from '@bv/utils-old';
import * as singleSpa from 'single-spa';

type THeaderProps = {
  title: string;
};

function getInitials(name: string): string {
  const parts = name.trim().split(/\s+/);

  const initials = parts
    .slice(0, 2)
    .map((part) => part[0].toUpperCase())
    .join('');

  return initials;
}

export default function Header({ title }: THeaderProps) {
  const decodedToken = BVUtils.token.getDecodedToken();

  function logout() {
    window.localStorage.clear();
    BVUtilsOld.cookiesHelper.clearAuthCookies();
    window.location.href = '/login';
  }

  function clearUserViewType() {
    window.localStorage.removeItem('userViewType');
    window.location.reload();
  }

  return (
    <div className="rounded-t-1 flex items-center justify-between bg-white p-4">
      <h3 className="text-primary-dark text-3xl font-bold">{title}</h3>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex items-center">
            <Avatar>
              <AvatarFallback className="bg-primary-dark text-white">{getInitials(decodedToken.name)}</AvatarFallback>
            </Avatar>
            <ChevronDown className="ml-2 text-gray-700" size={24} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-white text-gray-700">
          {decodedToken.roles?.length > 1 && <DropdownMenuItem onClick={clearUserViewType}>Trocar tipo de usuário</DropdownMenuItem>}
          <DropdownMenuItem onClick={() => singleSpa.navigateToUrl('/perfil')}>Meus dados</DropdownMenuItem>
          <DropdownMenuItem onClick={logout}>Sair</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
